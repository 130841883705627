import styled from '@emotion/styled';
import { isEqual } from 'lodash';

import { FindAContractorConnector } from '../../../redux/connectors/FindAContractorConnector';
import Stars from '../../Stars';
import { connectLocation } from '../../location/LocationConnectors';
import useContractorLevels from '../useContractorLevels';

const FilterChipsSection = styled.div`
  margin-top: 12px;
  flex-wrap: nowrap;
  margin-bottom: 24px;
`;

const Title = styled.h4`
  font-size: 24px;
  font-weight: 700;
  text-wrap: nowrap;
  margin-right: 12px;
  margin-top: 3px;
`;

const ChipsList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding-left: 0;
  flex-wrap: wrap;
`;

const ResetItem = styled.li`
  display: flex;
  align-items: center;
`;

/* In order to assemble the applied filters, we need to get the labels from
 * different objects (certifications, contractorLevels, etc).
 * filters only have the values selected and not filter labels
 * This function is responsible for parsing the filters and returning The
 * applied filters with their labels.
 */
const parseFilters = ({ filters, badgeData, contractorLevels }) => {
  const appliedFilters = [];
  Object.keys(filters).forEach(filterType => {
    if (filterType === 'certifications') {
      filters[filterType].forEach(filter => {
        const certification = badgeData.find(cert =>
          isEqual(cert.value, filter)
        );
        if (certification) {
          appliedFilters.push({
            key: filterType,
            label: certification.label,
            value: certification.value,
          });
        }
      });
    }
    if (filterType === 'additional_languages') {
        if(filters[filterType].includes('SS')) {
          appliedFilters.push({
            key: filterType,
            label: 'Spanish',
            value: 'SS',
          });
        }
    }

    if (filterType === 'contractor_level') {
      filters[filterType].forEach(filter => {
        const contractorLevel = contractorLevels.find(
          level => level.key === filter
        );
        if (contractorLevel) {
          appliedFilters.push({
            key: filterType,
            label: contractorLevel.value,
            value: contractorLevel.key,
          });
        }
      });
    }
    if (filterType === 'star_rating') {
      filters[filterType].forEach(filter => {
        if (filter > 0) {
          appliedFilters.push({
            key: filterType,
            label: (
              <>
                <Stars stars={filter} /> & Up
              </>
            ),
            value: 0,
          });
        }
      });
    }
  });

  return { appliedFilters };
};

export const FACFilterChips = FindAContractorConnector(
  connectLocation(({ filters, badgeData, toggleFilter, resetFilters, t }) => {
    const { contractorLevels } = useContractorLevels({ t });
    const { appliedFilters } = parseFilters({
      filters,
      badgeData,
      contractorLevels,
    });

    if (!appliedFilters.length) {
      return null;
    }

    return (
      <FilterChipsSection className="applied-filters-container">
        <ChipsList>
          <li>
            <Title>{t('shingles.noResult.appliedFilters')}:</Title>
          </li>
          {appliedFilters.map(filter => (
            <li key={filter.value}>
              <button
                type="button"
                className="applied-filter"
                onClick={() => toggleFilter({ [filter.key]: filter.value })}
              >
                {filter.label}
                <span className="close" aria-hidden="true">
                  &times;
                </span>
              </button>
            </li>
          ))}
          <ResetItem>
            <button
              type="button"
              className="oc-icon reset-filter"
              onClick={resetFilters}
              data-track="reset"
            >
              <i className="icon reset" aria-hidden="true" />
              {t('shingles.filters.reset')}
            </button>
          </ResetItem>
        </ChipsList>
      </FilterChipsSection>
    );
  })
);
