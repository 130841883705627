import _ from 'lodash';
import {connect} from 'react-redux';
import { filterContractors } from '../../components/FindAContractor/Filter';
import {
  LOAD_DATA,
  SET_MAX_DISTANCE,
  SET_SORT_BY,
  TOGGLE_FILTER,
  RESET_FILTERS,
  SET_MOBILE_FILTERS_OPENED,
} from '../actions/FindAContractorActions';

const DEFAULT_INITIAL_STATE = {
  api: {
    contractors: [],
  },
  filters: {
    contractor_level: [],
    certifications: [],
    additional_languages: [],
    star_rating: [0],
  },
  maxDistance: 20,
  autoExpandedRange: false,
  sortBy: 'default',
  mobileFiltersOpened: false,
};

export const findAContractorReducer = (state, action) => {
  const newState = _.cloneDeep(_.assign({}, DEFAULT_INITIAL_STATE, state));

  newState.autoExpandedRange = false;

  if (action.type === LOAD_DATA) {
    newState.api = action.data

    if (action.data.radius !== newState.maxDistance) {
      newState.maxDistance = action.data.radius;
      newState.autoExpandedRange = true;
    }

    return newState;
  }

  if (action.type === SET_MAX_DISTANCE) {
    newState.maxDistance = action.maxDistance;
    return newState;
  }

  if (action.type === SET_SORT_BY) {
    newState.sortBy = action.sortBy;
    return newState;
  }

  if (action.type === RESET_FILTERS) {
    newState.filters = {
      contractor_level: [],
      certifications: [],
      additional_languages: [],
      star_rating: [0],
    };
    return newState;
  }

  if (action.type === TOGGLE_FILTER) {
    const [[ key, value ]] = Object.entries(action.filter);

    if (key === 'star_rating') {
      newState.filters[key] = [value]
    } else {
      newState.filters[key] = _.xorWith(newState.filters[key], [value], _.isEqual)
    }

    return newState;
  }

  if (action.type === SET_MOBILE_FILTERS_OPENED) {
    newState.mobileFiltersOpened = action.mobileFiltersOpened;
    return newState;
  }

  return newState;
};

const INVALID_ZIP_ERROR = 'zip_code_not_found';

export const updateAPI = (data) => ({ type: LOAD_DATA, data })
export const setMaxDistance = (maxDistance) => ({ type: SET_MAX_DISTANCE, maxDistance })
export const setSortBy = (sortBy) => ({ type: SET_SORT_BY, sortBy })
export const toggleFilter = (filter) => ({ type: TOGGLE_FILTER, filter })
export const setMobileFiltersOpened = mobileFiltersOpened => ({
  type: SET_MOBILE_FILTERS_OPENED,
  mobileFiltersOpened,
});
export const resetFilters = () => ({ type: RESET_FILTERS })

export const FindAContractorConnector = connect(
  ({findAContractor}) => {
    const contractors = _.get(findAContractor, "api.contractors", [])
    const filteredContractors =  findAContractor.filters ? filterContractors(contractors, findAContractor.filters) : contractors
    return (
      {
        city: _.get(findAContractor, 'api.city'),
        contractors,
        filteredContractors,
        filteredContractorsCount: filteredContractors.length,
        filters: findAContractor.filters,
        invalidZip: _.get(findAContractor, 'api.meta.errors', []).includes(INVALID_ZIP_ERROR),
        maxDistance: findAContractor.maxDistance,
        autoExpandedRange: findAContractor.autoExpandedRange,
        region: _.get(findAContractor, 'api.region'),
        sortBy: findAContractor.sortBy,
        totalCount: contractors.length,
        newSort: findAContractor.newSort,
        mobileFiltersOpened: findAContractor.mobileFiltersOpened
      }
    );
  },
  { updateAPI, setMaxDistance, setSortBy, toggleFilter, resetFilters, setMobileFiltersOpened }
)
