import _ from 'lodash';

import { filterContractors, filters } from './Filter';
import useContractorLevels from './useContractorLevels';
import { FindAContractorConnector } from '../../redux/connectors/FindAContractorConnector';
import {
  FilterContent,
  FilterHeading,
  FilterSectionHeading,
  FilterWrapper,
} from '../ComponentLibrary/text-elements/filters';
import FilterToggle, {
  MULTISELECT_STRATEGY,
  FILTERS_WITH_VALUE,
  RADIO,
} from '../FilterToggle';
import LocationModalOpener from '../LocationModalOpener';
import Stars from '../Stars';
import { If } from '../Utilities';
import { connectLocation } from '../location/LocationConnectors';

const ContractorLevelFilter = ({ levels, openModal, ...props }) =>
  levels.map(level => (
    <FilterToggle
      key={level.key}
      filterKey="contractor_level"
      name={level.value}
      value={level.key}
      onInfo={() => openModal({ details: level.modal })}
      filterFunc={filterContractors}
      filtersConfig={filters}
      items={props.contractors}
      {...props}
    />
  ));

const ContractorCertificationFilter = connectLocation(({ t, badgeData, openModal, ...props }) => (
  (badgeData || []).map(cert => {
    return (
      <FilterToggle
        key={cert.value}
        filterKey="certifications"
        name={cert.label}
        value={cert.value}
        icon={cert.icon.file}
        onInfo={() => openModal(cert.value)}
        filterFunc={filterContractors}
        filtersConfig={filters}
        filterStrategy={MULTISELECT_STRATEGY}
        items={props.contractors}
        {...props}
      />
    )
  })
))

const AdditionalLanguageFilter = connectLocation(({ t, ...props }) => (
  <FilterToggle
    key="Spanish"
    filterKey="additional_languages"
    value="SS"
    name="Spanish"
    filterFunc={filterContractors}
    filterStrategy={MULTISELECT_STRATEGY}
    filtersConfig={filters}
    items={props.contractors}
    {...props}
  >
    <span aria-hidden="true">&nbsp; {t('contractors.additional_languages.labels.spanish')}</span>
  </FilterToggle>
));


const StarRatingFilter = connectLocation(({ t, ...props }) => (
  [4, 3, 2, 1, 0].map(rating => {
    return (
      <FilterToggle
        key={rating}
        filterKey="star_rating"
        value={rating}
        filterFunc={filterContractors}
        filtersConfig={filters}
        items={props.contractors}
        defaultEnabled={false}
        type={RADIO}
        inputLabel={rating === 0 ? t('contractors.all_ratings') : `${rating} ${t(`contractors.star${rating > 1 ? 's' : ''}`)} ${t('contractors.and_up_html')}`}
        {...props}
      >
        {
          rating > 0
            ? (
              <>
                <Stars stars={rating} />
                <span aria-hidden>&nbsp;{t('contractors.and_up_html')}</span>
              </>
            )
            : t('contractors.all_ratings')
        }
      </FilterToggle>
    )
  })
))

const DISTANCE_OPTIONS = [
  'contractors.max_distance.option_1',
  'contractors.max_distance.option_2',
  'contractors.max_distance.option_3',
];

/* eslint-disable react/prop-types */
const DistanceOption = ({ translationKey, t, isMobileOnly }) => {
  const distance = t(translationKey);

  return (
    <option data-testid={`${distance}-miles`} value={distance} label={distance}>
      {
        isMobileOnly ? distance : `${distance} ${t('contractors.miles')},` // when on desktop we add 'miles of' so it makes more sense when screen reader reads it
      }
    </option>
  );
};
/* eslint-disable react/prop-types */

// contractors, filters, maxDistance, setMaxDistance, toggleFilter from FindAContractorConnector
// t, zip from connectLocation
const XFACSidebar = (
  { bot, badgeData, contractors, filters, maxDistance, openModal, setMaxDistance, t, toggleFilter, resetFilters, zip, isMobileOnly, setMobileFiltersOpened }
) => {
  const { contractorLevels } = useContractorLevels({ t });

  const is_hidden = (items, filterStrategy, filterKey) => {
    return _.every(
      items,
      (item) => _.isEmpty(
        filterContractors(contractors, FILTERS_WITH_VALUE[filterStrategy]({
          filters,
          filterKey,
          value: item.value,
        }))));
  };

  const is_hidden_additional_languages = (filters, filterStrategy, filterKey) => {
    return _.isEmpty(
      filterContractors(contractors, FILTERS_WITH_VALUE[filterStrategy]({ filters, filterKey, value: 'SS' }))
    );
  };

  const onFilterClose = () => {
    setMobileFiltersOpened(false);
  }

  const onResetFilters = () => {
    resetFilters();
    setMobileFiltersOpened(false);
  }

  return (
    <>
      <div id="mobile-filters" className="col-section-container tablet-mobile-overlay" style={{ zIndex: 999 }}>
        <div className="mobile-filter-heading">
          <FilterSectionHeading>
            {t('contractors.filters_credentials_heading')}
          </FilterSectionHeading>
          <a href="#!" className="close mobile-tablet-only" onClick={onFilterClose}>
            &times;
          </a>
        </div>

        <a href="#contractor-list" className="skip-to-results">{t('contractors.skip_to_results')}</a>

        <FilterWrapper className="no-top">
          <FilterHeading>{t('contractors.location')}</FilterHeading>
          <FilterContent>
            <label title="radius of miles from your selected zipcode">
              {t('contractors.showing_pros')}
            </label>
            <br />

            <form className="oc-form" autoComplete="off">
              <fieldset>
                <div className="select-container">
                  <select
                    value={maxDistance}
                    onChange={evt => setMaxDistance(evt.target.value)}
                    name="radius"
                    id="select-radius"
                  >
                    {DISTANCE_OPTIONS.map(distance => (
                      <DistanceOption
                        key={distance}
                        translationKey={distance}
                        t={t}
                        isMobileOnly={isMobileOnly}
                      />
                    ))}
                  </select>
                </div>
                <span>{t('contractors.miles')}</span> <strong>{zip}</strong>
                <If condition={!bot}>
                  <LocationModalOpener
                    className="change-zip-small"
                    ariaLabel="change zipcode"
                    dataTrack="change-zip-open"
                    text={t('contractors.change')}
                    showIcon={false}
                  />
                </If>
              </fieldset>
            </form>
          </FilterContent>
        </FilterWrapper>

        <fieldset className="filter-fieldset">
          <FilterWrapper>
            <FilterHeading id="filter_contractor_level">{t('contractors.contractor_levels')}</FilterHeading>
            <FilterContent>
              <ul tabIndex="0" aria-labelledby="filter_contractor_level">
                <ContractorLevelFilter
                  levels={contractorLevels}
                  filters={filters}
                  contractors={contractors}
                  onToggleFilter={toggleFilter}
                  openModal={openModal}
                />
              </ul>
            </FilterContent>
          </FilterWrapper>
        </fieldset>

        <fieldset className={`filter-fieldset ${is_hidden(badgeData, MULTISELECT_STRATEGY, 'certifications') ? 'hidden' : null}`}>
          <FilterWrapper>
            <FilterHeading id="filter_contractor_certifications">
              {t('contractors.certifications')}
            </FilterHeading>
            <FilterContent>
              <ul tabIndex="0" aria-labelledby="filter_contractor_certifications">
                <ContractorCertificationFilter
                  badgeData={badgeData}
                  filters={filters}
                  contractors={contractors}
                  onToggleFilter={toggleFilter}
                  openModal={openModal}
                />
              </ul>
            </FilterContent>
          </FilterWrapper>
        </fieldset>

        <fieldset className={`filter-fieldset ${is_hidden_additional_languages(filters, MULTISELECT_STRATEGY,  'additional_languages') ? 'hidden'  : null}`}>
          <FilterWrapper>
            <FilterHeading id="filter_contractor_additional_languages">{t('contractors.additional_languages.title')}</FilterHeading>
            <FilterContent>
              <ul tabIndex="0" aria-labelledby="filter_contractor_additional_languages">
                <AdditionalLanguageFilter
                  badgeData={badgeData}
                  filters={filters}
                  contractors={contractors}
                  onToggleFilter={toggleFilter}
                  openModal={openModal}
                />
              </ul>
            </FilterContent>
          </FilterWrapper>
        </fieldset>

        <fieldset className="filter-fieldset">
          <FilterWrapper>
            <FilterHeading id="filter_contractor_star_rating">{t('contractors.star_rating')}</FilterHeading>
            <FilterContent>
              <ul tabIndex="0" aria-labelledby="filter_contractor_star_rating">
                <StarRatingFilter
                  filters={filters}
                  contractors={contractors}
                  onToggleFilter={toggleFilter}
                />
              </ul>
            </FilterContent>
          </FilterWrapper>
        </fieldset>
        <div className="mobile-only">
          <div style={{ height: 20 }} />
        </div>

        <div className="filter-control">
          <a className="control" href="#" onClick={onResetFilters}>
            {t('contractors.reset')}
          </a>

          <a href="#" className="control apply-filter" onClick={onFilterClose}>
            {t('contractors.apply_filters')}
          </a>
        </div>
      </div>

      <p
        className="footnote"
        dangerouslySetInnerHTML={{ __html: t('contractors.disclaimer') }}
      />
    </>
  );
};
export const FACSidebar = _.flow(
  FindAContractorConnector,
  connectLocation
)(XFACSidebar);
